<template>
  <div class="content">
    <base-header class="pb-6">
      <div class="row align-items-center py-4">
        <div class="col-lg-6 col-7">
          <h6 class="h2 text-white d-inline-block mb-0"></h6>
        </div>
      </div>
    </base-header>
    <div class="container-fluid mt--6">
      <div class="row">
        <div class="col-xl-12 mb-8 mb-xl-0">
          <card header-classes="bg-primary" type="secondary">
            <template v-slot:header>
              <div class="row align-items-center text-center">
                <div class="col py-1">
                  <h2 class="text-uppercase ls-1 mb-1" style="color: white">
                    ตั้งค่า PAYMENTGATEWAY
                  </h2>
                </div>
              </div>
            </template>
            <div class="row">
              <div class="col-md-12">
                <div
                  class="row text-center mt-4 mb-4"
                  v-show="Role.tw_status === '1'"
                >
                  <div class="col-md-3"></div>
                  <div class="col-md-3">
                    <p>สถานะฝาก</p>
                    <label class="custom-toggle">
                      <input type="checkbox" v-model="Form.depstatus" />
                      <span
                        class="custom-toggle-slider rounded-circle"
                        data-label-off="ปิด"
                        data-label-on="เปิด"
                      >
                      </span>
                    </label>
                  </div>
                  <div class="col-md-3">
                    <p>สถานะถอน</p>
                    <label class="custom-toggle">
                      <input type="checkbox" v-model="Form.widstatus" />
                      <span
                        class="custom-toggle-slider rounded-circle"
                        data-label-off="ปิด"
                        data-label-on="เปิด"
                      >
                      </span>
                    </label>
                  </div>
                  <div class="col-md-3"></div>
                </div>
                <div class="row" v-show="Role.tw_pw_pin === '1'">
                  <div class="col-md-4">
                    <div class="form-group">
                      <label for="key_id" class="form-control-label"
                        >COMPANYID</label
                      >
                      <input
                        id="key_id"
                        name="key"
                        type="text"
                        class="form-control input-group-alternative"
                        v-model="Form.compid"
                      />
                    </div>
                  </div>
                  <div class="col-md-4">
                    <div class="form-group">
                      <label for="Logintoken_id" class="form-control-label"
                        >TYPE_GATEWAY</label
                      >
                      <input
                        id="Logintoken_id"
                        name="Logintoken"
                        type="text"
                        class="form-control input-group-alternative"
                        v-model="Form.typegate"
                      />
                    </div>
                  </div>
                  <div class="col-md-4">
                    <div class="form-group">
                      <label for="TMN_id" class="form-control-label"
                        >TOKEN</label
                      >
                      <input
                        id="TMN_id"
                        name="TMNid"
                        type="password"
                        class="form-control input-group-alternative"
                        v-model="Form.authtoken"
                      />
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-12 text-center">
                    <button
                      type="submit"
                      class="btn btn-primary w-50"
                      @click="onSubmit"
                    >
                      บันทึก
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div class="container-fluid mt-6">
              <div class="row">
                <div class="col-xl-12 mb-8 mb-xl-0">
                  <card header-classes="bg-default">
                    <template v-slot:header>
                      <div class="row align-items-center text-center">
                        <div class="col py-1">
                          <h2
                            class="text-uppercase ls-1 mb-1"
                            style="color: white"
                          >
                            เติมเงินเข้าบัญชีPayment
                          </h2>
                        </div>
                      </div>
                    </template>
                    <div class="row justify-content-center">
                      <div class="col-md-4">
                        <div class="form-group text-center">
                          <label for="phone_id" class="form-control-label"
                            >ระบุ เลขบัญชี ,ชื่อ-นามสกุล
                            ของบัญชีที่ใช้ในการโอน</label
                          >
                          <br />
                          <input
                            id="accno_id"
                            name="accno"
                            inputmode="numeric"
                            v-model="payForm.accno"
                            class="form-control col-md-12 text-center"
                            placeholder="เลขบัญชี"
                          />
                          <input
                            id="name_id"
                            name="name"
                            type="text"
                            v-model="payForm.name"
                            class="form-control col-md-6 text-center"
                            :style="{ display: 'inline' }"
                            placeholder="ชื่อ"
                          />
                          <input
                            id="surname_id"
                            name="surname"
                            type="text"
                            v-model="payForm.surname"
                            class="form-control col-md-6 text-center"
                            :style="{ display: 'inline' }"
                            placeholder="นามสกุล"
                          />
                        </div>
                        <div class="form-group text-center">
                          <label for="phone_id" class="form-control-label"
                            >ระบุยอดที่ต้องการชำระ</label
                          >
                          <input
                            id="amount_id"
                            name="amount"
                            v-model="payForm.amount"
                            type="text"
                            class="form-control input-group-alternative text-center"
                            placeholder="ชำระยอดขั้นต่ำ 1000 - สูงสุด 50000บาท/ครั้ง"
                          />
                        </div>
                      </div>
                    </div>
                    <div class="row justify-content-center form-group">
                      <button
                        class="btn btn-primary"
                        @click="genarateQR()"
                        id="submitAdd"
                        :disabled="
                          payForm.accno == '' ||
                          payForm.name == '' ||
                          payForm.surname == '' ||
                          payForm.amount < '1000' ||
                          payForm.amount > '50000'
                        "
                      >
                        ชำระเงิน
                      </button>
                    </div>
                  </card>
                </div>
              </div>
            </div>
            <div
              class="row justify-content-center form-group"
              :style="{
                alignItems: 'center',
                width: '100%',
                height: '1000px',
              }"
              v-if="shouldShowIframe"
            >
              <button class="form-group hidemenu" @click="hide">
                <i class="fas fa-times"></i>
              </button>
              <div class="warning-text">
                <ul :style="{ marginBottom: '0px' }">
                  <br />
                  <br />
                  <li>
                    คำเตือน!!! หากไม่ปฏิบัติตามกฏท่านจะไม่ได้รับเครดิตเงินฝาก
                  </li>
                  <li>ห้ามนำ QRCODE นี้กลับมาใช้ซ้ำ</li>
                  <li>ห้ามทำรายการหากเกินเวลาที่กำหนด ให้กดรับQRCODEใหม่</li>
                  <li>ห้ามกด QRCODE หลายๆรอบโดยไม่ทำการฝาก</li>
                </ul>
              </div>
              <iframe
                :src="iframeSrc"
                frameborder="0"
                style="width: 100%; height: 100%; overflow: hidden"
              ></iframe>
            </div>
            <div class="container-fluid mt--6">
              <div class="row">
                <div class="col-xl-12 mb-8 mb-xl-0">
                  <card header-classes="bg-default">
                    <template v-slot:header>
                      <div class="row align-items-center text-center">
                        <div class="col py-1">
                          <h2
                            class="text-uppercase ls-1 mb-1"
                            style="color: white"
                          >
                            ประวัติรับชำระเงิน
                          </h2>
                        </div>
                      </div>
                    </template>
                    <div>
                      <card
                        header-classes="bg-orange"
                        class="no-border-card"
                        body-classes="px-0 pb-1"
                        footer-classes="pb-2"
                      >
                        <template v-slot:header>
                          <h3 class="mb-0" style="color: white">
                            รายการชำระเงินทั้งหมด {{ CurcountCount }} รายการ
                          </h3>
                        </template>
                        <div>
                          <div
                            class="col-12 d-flex justify-content-center justify-content-sm-between flex-wrap"
                          >
                            <el-table
                              :data="queriedData"
                              row-key="id"
                              header-row-class-name="thead-light"
                            >
                              <el-table-column
                                min-width="150px"
                                align="center"
                                label="เลขที่บิล"
                              >
                                <template v-slot:default="props">
                                  {{ props.row.order_number }}
                                </template>
                              </el-table-column>
                              <el-table-column
                                min-width="150px"
                                align="center"
                                label="จำนวน"
                              >
                                <template v-slot:default="props">
                                  {{ props.row.amount }}
                                </template>
                              </el-table-column>
                              <el-table-column
                                min-width="150px"
                                align="center"
                                label="วันที่ / เวลา"
                              >
                                <template v-slot:default="props">
                                  {{ props.row.date }}
                                  <br />
                                  {{ props.row.time }}
                                </template>
                              </el-table-column>
                              <el-table-column
                                min-width="220px"
                                align="center"
                                label="สถานะ"
                              >
                                <template v-slot:default="props">
                                  <badge
                                    type="success"
                                    v-if="props.row.status === '2'"
                                    >สำเร็จ</badge
                                  >
                                </template>
                              </el-table-column>
                            </el-table>
                          </div>
                        </div>
                        <template v-slot:footer>
                          <div
                            class="col-12 d-flex justify-content-center justify-content-sm-between flex-wrap"
                          >
                            <div class="">
                              <p class="card-category">
                                แสดง {{ from + 1 }} ถึง {{ to }} จากทั้งหมด
                                {{ total }} รายการ
                              </p>
                            </div>
                          </div>
                        </template>
                      </card>
                    </div>
                  </card>
                </div>
              </div>
            </div>
          </card>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { HTTP } from "@/services/http-common";
import { useToast } from "vue-toastification";
import Notification from "../../../components/Notification.vue";
import "sweetalert2/dist/sweetalert2.css";
import { mapGetters } from "vuex";
import {
  ElTable,
  ElTableColumn,
  ElSelect,
  ElOption,
  ElInput,
} from "element-plus";

export default {
  components: {
    [ElSelect.name]: ElSelect,
    [ElOption.name]: ElOption,
    [ElTable.name]: ElTable,
    [ElInput.name]: ElInput,
    [ElTableColumn.name]: ElTableColumn,
  },
  data() {
    return {
      showModal: false,
      Form: {
        depstatus: false,
        widstatus: false,
        compid: "",
        typegate: "",
        authtoken: "",
      },
      payForm: {
        accno: "",
        name: "",
        surname: "",
        amount: "",
      },
      iframeSrc: "",
      shouldShowIframe: false,
      CurcountCount: "0",
      tableData: [],
      pagination: {
        perPage: 25,
        currentPage: 1,
        perPageOptions: [5, 10, 25, 50],
        total: 0,
      },
      searchQuery: "",
      propsToSearch: ["phone", "details", "credit", "time", "date"],
      tableData: [],
      fuseSearch: null,
      searchedData: [],
    };
  },
  created() {
    if (!this.Auth) {
      this.$router.push("/login");
    } else {
      if (this.Role.bank != "1") {
        this.$router.push("/dashboard");
      } else {
        this.fetchAllData();
        this.fetchDepData();
      }
    }
  },
  methods: {
    hide() {
      this.shouldShowIframe = false;
    },
    onSubmit() {
      if (
        this.Form.compid != "" &&
        this.Form.typegate != "" &&
        this.Form.authtoken != ""
      ) {
        HTTP.put(
          "/admin/bank_pp",
          {
            compid: this.Form.compid,
            typegate: this.Form.typegate,
            authtoken: this.Form.authtoken,
            widstatus: this.Form.widstatus === true ? "1" : "0",
            depstatus: this.Form.depstatus === true ? "1" : "0",
          },
          {
            headers: {
              Authorization: "Bearer " + localStorage.getItem("adb_token"),
            },
          }
        )
          .then((resp) => {
            if (resp.data.status != undefined && !resp.data.status) {
              this.runToast(
                "top-right",
                "danger",
                resp.data.msg != undefined
                  ? resp.data.msg
                  : "ไม่สามารถทำรายการได้ กรุณาลองใหม่"
              );
            } else {
              this.runToast(
                "top-right",
                "success",
                resp.data.msg != undefined ? resp.data.msg : "สำเร็จ"
              );
              this.fetchAllData();
            }
          })
          .catch((err) => {
            if (err.response) {
              if (err.response.status === 401) {
                this.$router.push("/login");
                this.$store.dispatch("logoutAuth");
              } else {
                this.runToast(
                  "top-right",
                  "danger",
                  err.response.data.msg != undefined
                    ? err.response.data.msg
                    : "ไม่สามารถโหลดข้อมูลได้ กรุณาลองใหม่"
                );
              }
            }
          });
      } else {
        this.runToast("top-right", "danger", "ข้อมูลไม่ครบถ้วน");
      }
    },
    genarateQR() {
      HTTP.post(
        `/admin/payservice?depQrgen`,
        {
          amount: this.payForm.amount,
          name: this.payForm.name,
          surname: this.payForm.surname,
          accno: this.payForm.accno,
        },
        {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("adb_token"),
          },
        }
      )
        .then((response) => {
          if (response.data.data) {
            // Extract UUID and construct the direct QR code image URL
            // this.qrCodeUrl = `https://api.vpayth.com/storage/prompt_pay/${uuidMatch[1]}.png`;
            // this.qrCodeUrl = response.data.data;
            // window.open(this.qrCodeUrl, "_blank").focus();
            this.iframeSrc = response.data.data;
            this.shouldShowIframe = true;
          } else {
            this.$toast.error(
              response.data.msg != undefined
                ? response.data.msg
                : "ทำรายการไม่สำร็จ โปรดทำรายการอีกครั้ง"
            );
          }
        })
        .catch((err) => {
          this.$toast.error(
            err.data.msg != undefined
              ? err.data.msg
              : "ไม่สามารถโหลดข้อมูลธนาคารได้ กรุณาลองใหม่"
          );
        });
    },
    fetchAllData() {
      HTTP.get("/admin/bank_pp", {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("adb_token"),
        },
      })
        .then((resp) => {
          if (resp.data.status != undefined && !resp.data.status) {
            this.runToast(
              "top-right",
              "danger",
              resp.data.msg != undefined
                ? resp.data.msg
                : "ไม่สามารถโหลดข้อมูลได้ กรุณาลองใหม่"
            );
          } else {
            this.Form.depstatus =
              resp.data.data.depstatus === "1" ? true : false;
            this.Form.widstatus =
              resp.data.data.widstatus === "1" ? true : false;
            this.Form.compid = resp.data.data.companyID;
            this.Form.typegate = resp.data.data.type_gateway;
            this.Form.authtoken = resp.data.data.authToken;
          }
        })
        .catch((err) => {
          if (err.response) {
            if (err.response.status === 401) {
              this.$router.push("/login");
              this.$store.dispatch("logoutAuth");
            } else {
              this.runToast(
                "top-right",
                "danger",
                err.response.data.msg != undefined
                  ? err.response.data.msg
                  : "ไม่สามารถโหลดข้อมูลได้ กรุณาลองใหม่"
              );
            }
          }
        });
    },
    fetchDepData() {
      HTTP.get("/admin/payservice?fetchDepData", {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("adb_token"),
        },
      })
        .then((resp) => {
          if (resp.data.status != undefined && !resp.data.status) {
            this.runToast(
              "top-right",
              "danger",
              resp.data.msg != undefined
                ? resp.data.msg
                : "ไม่สามารถโหลดข้อมูลได้ กรุณาลองใหม่"
            );
          } else {
            this.tableData = resp.data.data;
            this.CurcountCount = this.tableData.length;
          }
        })
        .catch((err) => {
          if (err.response) {
            if (err.response.status === 401) {
              this.$router.push({
                path: "/login",
              });
              this.$router.push("/login");
              this.$store.dispatch("logoutAuth");
            } else {
              if (err.response.data != "") {
                this.runToast("top-right", "danger", err.response.data.msg);
              } else {
                this.runToast(
                  "top-right",
                  "danger",
                  "ไม่สามารถโหลดข้อมูลได้ กรุณาลองใหม่"
                );
              }
            }
          }
        });
    },
    runToast(pos, type, ownText, ownIcon) {
      const content = {
        component: Notification,
        props: {
          ownText: ownText,
          ownIcon: ownIcon,
          text: type,
          type: type,
        },
      };
      const toast = useToast();
      toast(content, {
        hideProgressBar: true,
        icon: false,
        closeButton: false,
        position: pos,
      });
    },
  },
  computed: {
    ...mapGetters(["Auth"]),
    ...mapGetters(["Role"]),
    pagedData() {
      return this.tableData.slice(this.from, this.to);
    },
    queriedData() {
      if (!this.searchQuery) {
        return this.pagedData;
      }
      let result = this.tableData.filter((row) => {
        let isIncluded = false;
        for (let key of this.propsToSearch) {
          let rowValue = row[key].toString();
          if (rowValue.includes && rowValue.includes(this.searchQuery)) {
            isIncluded = true;
          }
        }
        return isIncluded;
      });

      return result.slice(this.from, this.to);
    },
    to() {
      let highBound = this.from + this.pagination.perPage;
      if (this.total < highBound) {
        highBound = this.total;
      }
      return highBound;
    },
    from() {
      return this.pagination.perPage * (this.pagination.currentPage - 1);
    },
    total() {
      return this.searchedData.length > 0
        ? this.searchedData.length
        : this.tableData.length;
    },
  },
};
</script>

<style></style>
